.wrapper {
    display: flex;
    flex-flow: column nowrap;
    background: #fff;
    padding: 20px;
    width: 100%;
}

.chartContainer {
    position: relative;
}

.chart {
    height: 400px;
    width: 100%;
    position: relative;
}

.chartLoader {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header {
    display: flex;
    flex-flow: row nowrap;
    gap: 30px;
    margin-bottom: 20px;
}