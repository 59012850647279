.wrapper {
	padding-bottom: 50px;
}

.credentials {
	display: flex;
	flex-flow: column nowrap;
	gap: 2px;
	overflow: hidden;
}

.filterStatus {
	display: flex;
	flex-flow: column nowrap;
	gap: 20px;
	width: 200px;
}