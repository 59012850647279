.wrapper {
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
    padding-bottom: 50px;
}

.header {
    margin-bottom: 20px;
}

.notFounded {
    font-size: 16px;
    margin-top: 10px;
}

.pagination {
	margin: 20px 0;
}

.textWrap {
    word-break: normal;
    white-space: break-spaces;
    padding: 2px 0;
}

.listModal {
    width: calc(100% - 50px) !important;
}