.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 110px;
    padding-bottom: 24px;
}

.line {
    min-height: 2px;
    max-height: 2px;
    width: 100%;
    margin-block: 0px 16px;
    margin-inline: 0px;
    background: rgb(214, 221, 232);
    border-radius: 1px;
    border-color: transparent;
}

.leftContent {
    display: flex;
    flex-direction: column;
}

.path {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 20px;
    margin-top: 32px;
    overflow: hidden;
    white-space: nowrap;

    &Text,
    &Slash {
        margin-right: 10px;
        font-family: 'Manrope', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #262626;
        cursor: pointer;
    }

    &Slash {
        color: black;
        cursor: default;
    }

    &ActiveText {
        color: #787E93;
        cursor: default;
        overflow-x: hidden;
    }
}

.title {
    display: flex;
    align-items: center;
    gap: 12px;
    height: 28px;
    margin-top: 7px;
    overflow: hidden;

    &Text {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        color: #262626;
    }
}

.rightContent {}