.form {
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
}

.checkboxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 15px;

    & label {
        margin-left: 0!important;
    }
}

.spin {
    display: flex;
    width: 100%;
    justify-content: center;
}

.inputWrapper {
    display: flex;
    gap: 10px;
    align-items: center;
}

.tagTooltip {
    position: relative;

    &Content {
        position: absolute;
        display: none;
        background: rgba(0,0,0,.85);
        padding: 6px 8px;
        color: #fff;
        z-index: 1;
        gap: 10px;
        flex-flow: column nowrap;

        &Table {
            display: flex;
            flex-flow: column nowrap;
            gap: 10px;
            font-size: 11px;
            
            div {
                display: flex;
                gap: 20px;
                justify-content: space-between;

                span {
                    width: 50%;
                }
            }
        }
    }
    
    &:hover {
        .tagTooltipContent {
            display: flex;
        }
    }
} 