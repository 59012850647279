$cw: 130px; // cell width
$scw: 50px; // small cell width
$cellPadding: 10px;
$border: 1px solid #000;

@mixin cell {
    padding: $cellPadding;
    display: flex;
}

.container {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
}

.table {
    font-size: 12px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    box-sizing: border-box;

    &Header {
        border-left: $border;
        border-bottom: $border;
        position: sticky;
        background: #F8FBFF;
        top: 0;
        display: grid;
        grid-template-columns: $cw $scw $cw $cw $cw $cw $cw $scw $scw $scw $scw $cw $cw;
        
        & > span {
            @include cell;
            border-top: $border;
            border-right: $border;
            display: flex;
            flex-flow: column nowrap;

            p {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    &Body {
        display: flex;
        flex-flow: column nowrap;
        border-left: $border;
    }

    &Item {
        width: 100%;
        display: flex;
        align-items: stretch;
        border-bottom: $border;

        &Title {
            @include cell;
            border-right: $border;
            width: $cw;
            flex-flow: column nowrap;
            gap: 10px;
        }

        &List {
            display: flex;
            flex-flow: column nowrap;
            
            &Source {
                border-bottom: $border;
                height: 100%;
                display: grid;
                grid-template-columns: $scw $cw $cw $cw $cw $cw $scw $scw $scw $scw $cw $cw;

                &>span {
                    @include cell;
                    border-right: $border;
                    height: 100%;
                    display: flex;
                    flex-flow: column nowrap;
                }

                &:last-child {
                    border-bottom: 0;
                }

                .actions {
                    display: flex;
                    flex-flow: row nowrap;
                    gap: 15px;
                }
            }
        }
    }
}


.icon {
    font-size: 20px;
}