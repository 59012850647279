.container {
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
    padding-bottom: 50px;
}

.header {
    display: flex;
    flex-flow: row nowrap;
    gap: 20px;
    justify-content: space-between;
    
    &LeftSide {
        display: flex;
        flex-flow: column nowrap;
        gap: 20px;
    }

    &Total {
        display: flex;
        gap: 5px;
        font-size: 16px;
    }
}