.wrapper {
    display: flex;
    flex-flow: column nowrap;
    background: #fff;
    padding: 20px;
    width: 100%;
}

.chart {
    height: 200px;
    width: 100%;
    position: relative;
}

.header {
    display: flex;
    flex-flow: row nowrap;
    gap: 30px;
    margin-bottom: 20px;
}

.select {
    width: 140px;
}

.platforms {
    display: flex;
    align-items: center;
    gap: 20px;
}

.checkbox {
    display: flex;
}

.checkboxLabel {
    margin-left: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.chartContainer {
    position: relative;
}

.chart {
    height: 400px;
    width: 100%;
    position: relative;
}

.chartLoader {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}