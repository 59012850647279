.container {
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
}

.block {
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
}

.actions {
    display: flex;
    gap: 20px;

    button {
        width: 100%;
    }
}