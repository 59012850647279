.form {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    gap: 20px;
}

.checkboxes {
    display: flex;
}

.spin {
    align-self: center;
}