.form {
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;   
}

.checkboxes {
    display: flex;
}

.spin {
    align-self: center;
}