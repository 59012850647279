.tooltipOverlay {
    :global {
        .ant-popover-inner {
            border-radius: 8px;
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
        }
        .ant-popover-inner-content {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            padding: 8px;
            color: #262626;
            font-size: 14px;
            font-family: Montserrat;
            line-height: 130%;
        }
    }
}