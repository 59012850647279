.disabled {
    color: red;
}

.enabled {
    color: green;
}

.disabled,
.enabled {
    align-self: flex-start;
}