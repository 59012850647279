.item {
    width: 100%;
}

.footer {
    margin-top: 8px;
    padding: 0 8px 4px
}

.error {
    border: 1px solid red;
}