.wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: stretch;
    height: 100vh;
    background: linear-gradient(180deg, #FFFFFF 27.65%, #FFFFFF 42.46%, #0EBFA1 100%);
    position: sticky;
    top: 0;

    &.normal {
        min-width: 64px;
        width: 64px;
        transition: width 0.5s;
    }

    &.expanded {
        width: 245px;
        transition: width 0.2s;
    }
}

.logoLink {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 62px;
    line-height: 1;
    transition: transform 0.2s;

    &:hover {
        transform: scale(1.15);
    }
}

.arrowsContainer {
    display: flex;
    width: 100%;
    padding: 10px;
    margin-top: 24px;

    &_arrows {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        padding: 10px;
        gap: 10px;
        cursor: pointer;
        background: none;
        margin: 0;
        border: none;

        &:hover {
            background-color: #DFFCF7;
            border-radius: 6px;
        }
    }

    &_arrows.normal {
        transform: rotate(0deg);
    }

    &_arrows.expanded {
        transform: rotate(180deg);
        margin-right: 0;
    }
}

.footer {
    margin-top: auto;
    display: flex;
    gap: 20px;
    padding-bottom: 15px;
    flex-flow: column nowrap;
    align-items: flex-start;
    padding-left: 10px;
    width: 100%;
}