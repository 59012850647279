.wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-height: 100vh;
    justify-content: flex-start;
    width: 100%;
}

.content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background: #F8FBFF;
    padding-left: 30px;
    padding-right: 30px;
}