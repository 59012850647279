.button {
    border: 1px solid rgba(222, 225, 231, 0.5);
    border-radius: 4px;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;

	&[disabled] {
		background: #E1E4E7;
		border: none;

		svg {
			path {
				stroke: #fff;
			}
		}
	}
}

.prev {
	svg {
		transform: rotate(180deg);
	}
}

.pagination {
	input {
		height: 28px;
		min-width: 64px;
		border: 6px;
		outline: none;
		border: 1px solid rgba(222, 225, 231, 0.5);
		&:focus {
			box-shadow: 0 0 0 2px rgba(14, 191, 161, 0.4);
			border-color: #0EBFA1;
			// box-shadow: none;
		}
		&:hover, &-active {
			border: 1px solid #0EBFA1;
			color: #0EBFA1;
	
			a {
				color: #0EBFA1;
			}
		}
	}
}

.containerCotroler {
	display: flex;
	align-items: center;
	font-size: 14px;
    font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 500;
	line-height: 135%;
	color: rgba(120, 126, 147, 0.70);
	margin-left: 50px;
	input {
		color: #787E93;
		width: 52px;
		height: 32px;
		border-radius: 4px;
		margin-left: 10px;
		margin-right: 24px;
		outline: none;
		padding: 8px;
		border: 1px solid rgba(222, 225, 231, 0.50)
	}
}

.btn {
	border-radius: 6px;
	border: 1px solid #0EBFA1;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	padding: 6px 12px;
	width: fit-content;
    font-family: 'Montserrat', sans-serif;
	color: #0EBFA1;
	cursor: pointer;
	user-select: none;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	transition: all 0.3s;
	&:hover {
		border-color: #2BD3B71A;
		background: #2BD3B71A;
	}
	&:active {
		background-color: #0DAA8F;
		border-color: rgba(0,0,0,0);
		color: #fff;

	}
}