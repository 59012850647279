

.wrapper {
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
    height: 100%;
    padding-bottom: 50px;
}

.tableItemTasks {
    display: flex;
    flex-flow: column nowrap;
    padding: 0;
    white-space: normal;

    & > div {
        border-bottom: 1px solid #000;
        padding-bottom: 5px;
        padding-top: 5px;
        display: flex;
        flex-flow: column nowrap;
        font-size: 12px;

        p {
            margin-bottom: 2px;
        }

        button {
            margin-top: 5px;
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    &Buttons {
        display: flex;
        gap: 10px;
    }
}

.tableItemIcon {
    display: flex;
    align-items: center;
}

.textWrap {
    word-break: break-all;
    word-wrap: break-word;
    white-space: normal;
}

.table {
    min-height: 200px;
}