.wrapper {
    display: flex;
    flex-flow: column nowrap;
    font-family: "Montserrat", sans-serif;
}

.title {
    margin-bottom: 10px;
    color: #7c848b;
    font-weight: 500;
    font-size: 14px;
}

.numberSort {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px;
    width: fit-content;
    button {
        display: flex;
        gap: 8px;
        align-items: center;

        span {
            font-weight: 500;
            font-size: 14px;
            white-space: nowrap;
            color: #262626;
        }

        &:last-child svg {
            transform: scale(1, -1);
        }
    }
}

.textSort {
    button {
        display: flex;
        gap: 8px;
        align-items: center;

        p {
            margin-bottom: 0;
            font-weight: 500;
            font-size: 14px;
        
            span {
                margin: 0 3px;
            }
        }

        &:last-child svg {
            transform: scale(1, -1);
        }


        &[data-active="true"] {
            p span {
                font-weight: 700;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    width: 14px;
                    height: 2px;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #262626;
                    border-radius: 1px;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 0;
                }
            }
        }
    }
}
