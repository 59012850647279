.ant-pagination-item {
	border: 1px solid rgba(222, 225, 231, 0.5);
	border-radius: 4px;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;

	&:hover, &-active {
		border: 1px solid #0EBFA1;
		color: #0EBFA1;

		a {
			color: #0EBFA1;
		}
	}
}