.button {
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;
    
    .iconUp,
    .iconDown {
        color: #000;
    }
    
    .iconDown {
        margin-left: 0!important;
        margin-top: -0.3em;
    }

    .iconActive {
        color: #0EBFA1;
    }
}