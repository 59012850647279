.item {
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    margin: 0 0 4px;
    padding: 0;
    border-radius: 3px;
    overflow: hidden;

    &.expanded {
        justify-content: flex-start;
        padding-left: 10px;
    }

    &.normal {
        justify-content: center;
    }
}

.link {
    display: flex;
    flex-direction: row;
    min-width: 60px;

    &.normal {
        justify-content: center;
    }

    &.expanded {
        justify-content: flex-start;
    }

    &Active {
        .linkItem {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            // width: 40px;
            // height: 40px;
            background: #0EBFA1;
            border-radius: 3px;

            svg {
                fill: #fff;
            }
        }
    }
}

.linkTitle {
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #262626;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
        background-color: #DFFCF7;
        ;
    }

    &.normal {
        opacity: 0;
    }

    &.expanded {
        opacity: 1;
        margin-right: 10px;
        padding: 10px;
        margin-left: 10px;
    }
}

.linkItem {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0EBFA1;
    padding: 10px;
    line-height: 1;
    font-size: 20px;
    transition: background-color 0.3s;
    cursor: pointer;

    &:hover {
        color: #0EBFA1;
    }
}