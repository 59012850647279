.wrapper {
    display: flex;
    position: relative;
    width: fit-content;
}

.container {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
}

.cell {
    display: flex;
    gap: 10px;
    align-items: center;
    height: 44px;
    padding: 0 12px;

    &Value {
        font-size: 14px;
        font-weight: 500;
        color: #787e93;
        display: flex;
        align-items: center;
    }
}

.cellButton {
    gap: 10px;
    padding: 6px 8px;
    cursor: pointer;
    margin-left: -10px;

    &:hover,
    &Active {
        background: #e8f1f9;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
    }

    .sortingArrow {
        display: flex;
        align-items: center;
    }

    &Active .sortingArrow {
        transform: rotate(180deg);
    }
}

.modal {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    position: absolute;
    top: 44px;
    padding: 12px;
    left: 0;
    min-width: 170px;
}

.searchButton {
    display: flex;
    align-items: center;
}

.cellSearch {
    padding: 10px;
    position: absolute;
    width: 355px;
    top: 36px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    z-index: 9;
    input {
        border-radius: 6px;
        border: 1px solid rgba(222, 225, 231, 0.5);
        height: 48px;
        background: #fff;
        width: 100%;
        font-size: 16px;
        font-family: Montserrat;
        font-weight: 500;
        color: #262626;

        &:focus {
            border: 1px solid #129fd4;
        }
    }

    button {
        position: absolute;
        right: 22px;
        top: 50%;
        transform: translateY(-50%);
        height: 16px;
    }
}
