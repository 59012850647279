@import "~antd/dist/antd.css";

#root {
	height: 100%;
}

button {
	background: none;
	padding: 0;
	border: none;
}